import React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  sx?: any;
}

export const CloseIcon = ({ sx }: Props) => (
  <SvgIcon sx={sx}>
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0885 21.443L0.00195312 37.53L4.08452 41.6127L20.1711 25.5257L37.5375 42.8926L41.6201 38.8099L24.2536 21.443L41.614 4.08219L37.5314 -0.000488281L20.1711 17.3603L4.09058 1.27945L0.00801017 5.36213L16.0885 21.443Z"
        fill="black"
      />
    </svg>
  </SvgIcon>
);
