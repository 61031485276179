import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Link,
  Stack,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { MembershipDialogContext } from "../context/MembershipDialogContext";
import { CloseIcon, MenuIcon } from "../icons";
import { Logo } from "./Logo";
import navigation from "../constants/navigation";
import { LanguageSwitcher } from "./LanguageSwitcher";

export const MobileMenu: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const isMedium = useMediaQuery(theme.breakpoints.up("sm"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const membershipDialogContext = useContext(MembershipDialogContext);

  return (
    <>
      <IconButton onClick={() => setIsMenuOpen(true)}>
        <MenuIcon />
      </IconButton>
      <Dialog
        fullScreen
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        PaperProps={{ sx: { backgroundColor: "#F5F5F5" } }}
      >
        <AppBar component="nav" position={"static"} elevation={0}>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              height: 120,
            }}
          >
            <IconButton onClick={() => setIsMenuOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Stack
          direction={"column"}
          alignItems={"flex-start"}
          spacing={4}
          p={4}
          pt={0}
          sx={{
            "& .MuiLink-root": {
              fontSize: 16,
              fontWeight: 500,
              lineHeight: "26px",
            },
          }}
        >
          {navigation.map((route) => (
            <Link
              key={route.name}
              href={route.route}
              className={"nav-link"}
              underline={"none"}
              noWrap
              sx={{
                margin: "14px !important",
                fontSize: "24px !important",
                lineHeight: "42px !important",
              }}
            >
              {t(route.label)}
            </Link>
          ))}
          <LanguageSwitcher
            className={"nav-link"}
            sx={{
              margin: "14px !important",
              fontSize: "24px !important",
              lineHeight: "42px !important",
            }}
          />
        </Stack>
      </Dialog>
    </>
  );
};
