import React from "react";
import { Box, Container, Grid, Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetMainPageDataQuery } from "../queries/generated/getMainPage.graphql";
import { fileUrl } from "../apiClient";
import { PartnerLogo } from "../components/PartnerLogo";

const FooterBox = styled("footer")(({ theme }) => ({
  color: "#fff",
  backgroundColor: "#494949",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "auto",
  padding: `50px`,
  [theme.breakpoints.down("sm")]: {
    padding: `25px`,
  },
}));

const FooterTitle = styled(Typography)(({ theme }) => ({
  color: "#fff",
  fontFamily: "Lexend",
  fontSize: "24px",
  fontWeight: 700,
  lineHeight: "109%",
  textTransform: "uppercase",
  marginTop: "36px",
  marginBottom: "36px",
  [theme.breakpoints.down("sm")]: {
    textAlign: "center",
  },
}));

const CopyText = styled(Typography)(() => ({
  color: "#A6A6A6",
  fontFamily: "Lexend",
  fontSize: "14px",
  fontWeight: 300,
}));

export default function Footer() {
  const { t } = useTranslation();

  const { data } = useGetMainPageDataQuery();

  return (
    <FooterBox>
      <Container maxWidth={"lg"}>
        <FooterTitle>{t("paticka.partneri")}</FooterTitle>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-evenly"
          alignItems="center"
          spacing={{ xs: 7, md: 4 }}
          pt={{ xs: 4, md: 0 }}
          mb={12}
        >
          {data?.partners.map((partner) => {
            const logoUrl = fileUrl(partner.logo!.id);
            return (
              <PartnerLogo
                alt={partner.alt}
                logo={logoUrl}
                link={partner.link}
              />
            );
          })}
        </Stack>
      </Container>
      <CopyText>{t("paticka.copy")}</CopyText>
    </FooterBox>
  );
}
