import { SvgIcon, SxProps } from "@mui/material";
import { FC } from "react";

interface Props {
  sx?: SxProps;
}
export const MembershipIcon: FC<Props> = ({ sx }) => (
  <SvgIcon sx={sx}>
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_721_1707)">
        <path
          d="M28.9275 14.9999C28.9275 17.7547 28.1106 20.4476 26.5801 22.7382C25.0496 25.0287 22.8742 26.814 20.3291 27.8682C17.784 28.9224 14.9834 29.1982 12.2816 28.6608C9.57968 28.1234 7.09785 26.7968 5.1499 24.8488C3.20196 22.9009 1.87539 20.4191 1.33795 17.7172C0.800515 15.0153 1.07635 12.2147 2.13057 9.66963C3.18479 7.12451 4.97005 4.94917 7.26059 3.41868C9.55113 1.88819 12.2441 1.07129 14.9989 1.07129"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.4978 1.07129L11.7835 11.7856L9.64062 20.357L18.2121 18.2141L28.9263 7.49986"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_721_1707">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
