import * as Types from '../../types/gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAboutUsPersonDataQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetAboutUsPersonDataQuery = { __typename?: 'Query', committee_members_by_id?: { __typename?: 'committee_members', id: string, name: string, translations?: Array<{ __typename?: 'committee_members_translations', id: string, position?: string | null, bio?: string | null, languages_code?: { __typename?: 'languages', code: string } | null } | null> | null, photo?: { __typename?: 'directus_files', id: string } | null } | null };


export const GetAboutUsPersonDataDocument = gql`
    query getAboutUsPersonData($id: ID!) {
  committee_members_by_id(id: $id) {
    id
    name
    translations {
      id
      languages_code {
        code
      }
      position
      bio
    }
    photo {
      id
    }
  }
}
    `;

/**
 * __useGetAboutUsPersonDataQuery__
 *
 * To run a query within a React component, call `useGetAboutUsPersonDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAboutUsPersonDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAboutUsPersonDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAboutUsPersonDataQuery(baseOptions: Apollo.QueryHookOptions<GetAboutUsPersonDataQuery, GetAboutUsPersonDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAboutUsPersonDataQuery, GetAboutUsPersonDataQueryVariables>(GetAboutUsPersonDataDocument, options);
      }
export function useGetAboutUsPersonDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAboutUsPersonDataQuery, GetAboutUsPersonDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAboutUsPersonDataQuery, GetAboutUsPersonDataQueryVariables>(GetAboutUsPersonDataDocument, options);
        }
export function useGetAboutUsPersonDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAboutUsPersonDataQuery, GetAboutUsPersonDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAboutUsPersonDataQuery, GetAboutUsPersonDataQueryVariables>(GetAboutUsPersonDataDocument, options);
        }
export type GetAboutUsPersonDataQueryHookResult = ReturnType<typeof useGetAboutUsPersonDataQuery>;
export type GetAboutUsPersonDataLazyQueryHookResult = ReturnType<typeof useGetAboutUsPersonDataLazyQuery>;
export type GetAboutUsPersonDataSuspenseQueryHookResult = ReturnType<typeof useGetAboutUsPersonDataSuspenseQuery>;
export type GetAboutUsPersonDataQueryResult = Apollo.QueryResult<GetAboutUsPersonDataQuery, GetAboutUsPersonDataQueryVariables>;