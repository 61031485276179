import { createTheme } from "@mui/material";

export const pxToRem = (px: number) => `${px / 16}rem`;

const Theme = createTheme({
  palette: {
    primary: {
      main: "#DB565B",
      contrastText: "#fff",
    },
    text: {
      primary: "#494949",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 560,
      md: 840,
      lg: 1020,
      xl: 1480,
    },
  },
  typography: {
    fontFamily: [
      "SF Pro Display",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h2: {
      fontFamily: "Maitree",
      fontSize: pxToRem(66),
      fontWeight: 500,
      lineHeight: 1.1,

      "@media (max-width:560px)": {
        fontSize: pxToRem(42),
      },
    },
    h3: {
      fontFamily: "Maitree",
      fontSize: pxToRem(45),
      fontWeight: 500,
      lineHeight: 1.31,
      marginBottom: 30,

      "@media (max-width:560px)": {
        fontSize: pxToRem(35),
        marginBottom: 30,
      },
    },
    h4: {
      fontFamily: "Maitree",
      fontSize: pxToRem(35),
      fontWeight: 500,
      lineHeight: 1.35,
    },
    h5: {
      fontSize: pxToRem(24),
      fontWeight: 500,
      lineHeight: 1.35,
    },
    subtitle1: {
      fontFamily: "Maitree",
      fontSize: pxToRem(35),
      fontWeight: 500,
      lineHeight: 1.14,

      "@media (max-width:560px)": {
        fontSize: pxToRem(24),
      },
    },
    body1: {
      fontSize: pxToRem(21),
      fontWeight: 400,
      lineHeight: "142%",
    },
  },
});

Theme.components = {
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: "#F5F5F5",
        ".MuiLink-root": {
          color: Theme.palette.text.primary,
          fontSize: pxToRem(21),
          fontWeight: 500,
          lineHeight: 1.23,
        },
      },
    },
    defaultProps: {
      elevation: 0,
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        borderRadius: 100,
        fontSize: pxToRem(18),
        fontWeight: 500,
        lineHeight: 1.34,
        textTransform: "none",
        whiteSpace: "nowrap",
        minWidth: "222px",
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        "&.main": {
          paddingTop: "200px",
          paddingBottom: "200px",
          minHeight: "60vh",
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        "&.nav-link": {
          fontSize: pxToRem(21),
          fontWeight: 500,
          lineHeight: 1.23,
          color: Theme.palette.text.primary,
          "&.active": {
            color: Theme.palette.primary.main,
            fontWeight: 700,
          },
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: "#fff",
        borderRadius: 0,
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        borderRadius: 0,
      },
    },
  },
};
export default Theme;
