import React, { FC, useContext } from "react";
import {
  AppBar,
  Button,
  Container,
  Hidden,
  Link,
  Stack,
  styled,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import navigation from "../constants/navigation";
import { useTranslation } from "react-i18next";
import { Logo } from "../components/Logo";
import {
  MembershipDialogContext,
  MembershipDialogContextProvider,
} from "../context/MembershipDialogContext";
import { MobileMenu } from "../components/MobileMenu";
import { MembershipIcon } from "../icons";
import { LanguageSwitcher } from "../components/LanguageSwitcher";

const NavigationContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "120px",
  "& > *": {
    flexGrow: 1,
    marginRight: theme.spacing(2),
    maxWidth: 160,
  },
}));

const Navigation: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t, i18n } = useTranslation();
  const membershipDialogContext = useContext(MembershipDialogContext);

  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLowOnSpace = useMediaQuery(
    "(min-width: 1020px) and (max-width: 1224px)",
    {
      noSsr: true,
    },
  );

  return (
    <AppBar component={"nav"}>
      <Toolbar
        sx={{ display: "flex", justifyContent: "space-between", height: 120 }}
      >
        <Logo size={isMobile ? "small" : "large"} />
        <Hidden lgDown>
          {/*Desktop menu*/}
          <NavigationContainer>
            {navigation.map((route) => {
              const isSelect = window.location.pathname.startsWith(route.route);

              return (
                <Link
                  key={route.name}
                  href={route.route}
                  className={"nav-link " + (isSelect ? "active" : "")}
                  underline={"none"}
                  noWrap
                >
                  {t(route.label)}
                </Link>
              );
            })}
          </NavigationContainer>
        </Hidden>
        {/*Membership dialog and language switcher */}
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          spacing={2}
          sx={{
            maxWidth: "370px",
            flexGrow: 1,
          }}
        >
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{
              display: isLowOnSpace || isTabletOrMobile ? "block" : "none",
              height: 50,
              flex: "0 0 auto",
              width: 50,
              minWidth: 50,
              maxWidth: 50,
              padding: "10px",
              "& .MuiButton-startIcon": {
                marginRight: 0,
                marginLeft: 0,
              },
            }}
            startIcon={<MembershipIcon sx={{ fontSize: "30px !important" }} />}
            onClick={() => membershipDialogContext.setIsDialogOpen(true)}
          />
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{
              display: isLowOnSpace || isTabletOrMobile ? "none" : "block",
              height: 50,
              flex: "0 0 auto",
              padding: "10px",
              "& .MuiButton-startIcon": {
                marginRight: 0,
                marginLeft: 0,
              },
            }}
            onClick={() => membershipDialogContext.setIsDialogOpen(true)}
          >
            {t("navigace.chciSeStatClenem")}
          </Button>
          <Hidden mdDown>
            <LanguageSwitcher />
          </Hidden>
          <Hidden lgUp>
            <MobileMenu />
          </Hidden>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
