import React, { FC, useEffect } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../icons/CloseIcon";
import { useTranslation } from "react-i18next";
import { CloseDialogButton } from "../components/CloseDialogButton";
import { useForm } from "react-hook-form";
import { FormTextField } from "../components/FormTextField";
import { FormCheckboxField } from "../components/FormCheckboxField";
import { useSubmitApplicationMutation } from "../queries/generated/submitApplication.graphql";

interface Props {
  open: boolean;
  onClose: () => void;
}

const FormBackground = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  backgroundColor: "#F2F2F2",
  padding: "72px 38px",
}));

/**fullscreen membership dialog */
export const MembershipDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      dateOfBirth: "",
      email: "",
      occupation: "",
      phone: "",
      address: "",
      languageProficiency: "",
      interests: {
        culture: false,
        literature: false,
        music: false,
        folklore: false,
        history: false,
        economy: false,
        trade: false,
        languageStudy: false,
        science: false,
      },
      other: "",
      regionRelationship: "",
      suggestions: "",
      contribution: "",
    },
  });

  const [submitting, setSubmitting] = React.useState(false);
  const [submitted, setSubmitted] = React.useState(false);
  useEffect(() => {
    reset();
    setSubmitted(false);
  }, [open]);

  const [submitApplication] = useSubmitApplicationMutation();
  const onSubmit = async (formdata: any) => {
    setSubmitting(true);
    try {
      const data = {
        name: formdata.name,
        dateOfBirth: formdata.dateOfBirth,
        email: formdata.email,
        occupation: formdata.occupation,
        phone: formdata.phone,
        address: formdata.address,
        languageProficiency: formdata.languageProficiency,
        interests: Object.entries(formdata.interests)
          .filter(([, v]) => v)
          .map(([k]) => k),
        other: formdata.other,
        regionRelationship: formdata.regionRelationship,
        suggestions: formdata.suggestions,
        contribution: formdata.contribution,
      };
      const res = await submitApplication({
        variables: { data },
      });

      if (res.data?.create_application_item) {
        setSubmitted(true);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <Container>
        <Toolbar
          sx={{
            paddingTop: 7,
            paddingRight: "0 !important",
            paddingLeft: "0 !important",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={"h3"} color={"primary"}>
            {t("chciSeStatClenem.nadpis")}
          </Typography>
          <Hidden smDown>
            <CloseDialogButton
              onClick={onClose}
              sx={{ marginTop: { xs: 0, md: -2 } }}
            >
              {t("chciSeStatClenem.zavrit")}
            </CloseDialogButton>
          </Hidden>
          <Hidden smUp>
            <IconButton onClick={onClose} sx={{ marginTop: { xs: 0, md: -2 } }}>
              <CloseIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
        <FormBackground elevation={0}>
          <form onSubmit={handleSubmit(onSubmit)} onError={console.log}>
            <Grid container spacing={3.5}>
              <Grid item xs={12}>
                <Stack direction={"row"}>
                  <Typography variant={"h4"}>
                    {t("chciSeStatClenem.prihlaska")}
                  </Typography>
                </Stack>
              </Grid>
              {submitted && (
                <>
                  <Grid item xs={12}>
                    <Typography variant={"h5"}>
                      {t("chciSeStatClenem.odeslano")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      spacing={2}
                      direction={"column"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{ paddingTop: 5.5 }}
                    >
                      <Typography>{}</Typography>
                      <Button
                        variant={"contained"}
                        size={"large"}
                        color={"primary"}
                        onClick={onClose}
                        sx={{ minWidth: "220px" }}
                      >
                        {t("chciSeStatClenem.zavrit")}
                      </Button>
                    </Stack>
                  </Grid>
                </>
              )}
              {!submitted && (
                <>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name={"name"}
                      label={t("chciSeStatClenem.jmeno")}
                      control={control}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name={"dateOfBirth"}
                      label={t("chciSeStatClenem.datumNarozeni")}
                      control={control}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name={"email"}
                      label={t("chciSeStatClenem.email")}
                      control={control}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name={"occupation"}
                      label={t("chciSeStatClenem.povolani")}
                      control={control}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name={"phone"}
                      label={t("chciSeStatClenem.telefon")}
                      control={control}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      name={"address"}
                      label={t("chciSeStatClenem.adresa")}
                      control={control}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormTextField
                      name={"languageProficiency"}
                      label={t("chciSeStatClenem.jazykovaZpusobilost")}
                      control={control}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Stack>
                      <Typography
                        sx={{
                          color: "rgba(0, 0, 0, 0.50)",
                          fontSize: "18px",
                          fontWeight: 400,
                          lineHeight: "53px",
                        }}
                      >
                        {t("chciSeStatClenem.zajmoveZamereni.nadpis")}
                      </Typography>
                      <Grid container spacing={0.5}>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormCheckboxField
                            name={"interests.culture"}
                            label={t(
                              "chciSeStatClenem.zajmoveZamereni.kultura",
                            )}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormCheckboxField
                            name={"interests.literature"}
                            label={t(
                              "chciSeStatClenem.zajmoveZamereni.literatura",
                            )}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormCheckboxField
                            name={"interests.music"}
                            label={t("chciSeStatClenem.zajmoveZamereni.hudba")}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormCheckboxField
                            name={"interests.folklore"}
                            label={t(
                              "chciSeStatClenem.zajmoveZamereni.folklor",
                            )}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormCheckboxField
                            name={"interests.history"}
                            label={t("chciSeStatClenem.zajmoveZamereni.dejiny")}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormCheckboxField
                            name={"interests.economy"}
                            label={t(
                              "chciSeStatClenem.zajmoveZamereni.hospodarstvi",
                            )}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormCheckboxField
                            name={"interests.trade"}
                            label={t("chciSeStatClenem.zajmoveZamereni.obchod")}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormCheckboxField
                            name={"interests.languageStudy"}
                            label={t(
                              "chciSeStatClenem.zajmoveZamereni.studiumJazyka",
                            )}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <FormCheckboxField
                            name={"interests.science"}
                            label={t(
                              "chciSeStatClenem.zajmoveZamereni.vedniOblast",
                            )}
                            control={control}
                          />
                        </Grid>
                      </Grid>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <FormTextField
                      name={"other"}
                      label={t("chciSeStatClenem.ostatni")}
                      control={control}
                      multiline
                      minRows={2}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormTextField
                      name={"regionRelationship"}
                      label={t("chciSeStatClenem.vztahKRegionu")}
                      control={control}
                      multiline
                      minRows={2}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormTextField
                      name={"suggestions"}
                      label={t("chciSeStatClenem.namety")}
                      control={control}
                      multiline
                      minRows={3}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormTextField
                      name={"contribution"}
                      label={t("chciSeStatClenem.jakMuzetePrispet")}
                      control={control}
                      multiline
                      minRows={2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      spacing={2}
                      direction={"column"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{ paddingTop: 5.5 }}
                    >
                      <Typography>{}</Typography>
                      <Button
                        variant={"contained"}
                        size={"large"}
                        color={"primary"}
                        type={"submit"}
                        sx={{ minWidth: "220px" }}
                        disabled={submitting}
                      >
                        {t("chciSeStatClenem.odeslat")}
                      </Button>
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </FormBackground>
      </Container>
      <DialogContent></DialogContent>
    </Dialog>
  );
};
