import { useTranslation } from "react-i18next";
import { Box, Link, SxProps } from "@mui/material";
import { FC } from "react";

interface Props {
  className?: string;
  sx?: SxProps;
}
export const LanguageSwitcher: FC<Props> = (props) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      {i18n.language.startsWith("es") && (
        <Link
          underline={"none"}
          onClick={() => changeLanguage("cs")}
          {...props}
        >
          {t("jazyk.cs")}
        </Link>
      )}
      {!i18n.language.startsWith("es") && (
        <Link
          underline={"none"}
          onClick={() => changeLanguage("es")}
          {...props}
        >
          {t("jazyk.es")}
        </Link>
      )}
    </>
  );
};
