import * as Types from '../../types/gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetHomepageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetHomepageDataQuery = { __typename?: 'Query', homepage?: { __typename?: 'homepage', id: string, hero_image?: { __typename?: 'directus_files', id: string } | null, translations?: Array<{ __typename?: 'homepage_translations', id: string, text_title?: string | null, text?: string | null, languages_code?: { __typename?: 'languages', code: string } | null } | null> | null } | null, news: Array<{ __typename?: 'news', id: string, preview_image?: { __typename?: 'directus_files', id: string } | null, translations?: Array<{ __typename?: 'news_translations', id: string, title: string, text?: string | null, languages_code?: { __typename?: 'languages', code: string } | null } | null> | null }> };


export const GetHomepageDataDocument = gql`
    query getHomepageData {
  homepage {
    id
    hero_image {
      id
    }
    translations {
      id
      languages_code {
        code
      }
      text_title
      text
    }
  }
  news(sort: "-date_published", limit: 3) {
    id
    preview_image {
      id
    }
    translations {
      id
      languages_code {
        code
      }
      title
      text
    }
  }
}
    `;

/**
 * __useGetHomepageDataQuery__
 *
 * To run a query within a React component, call `useGetHomepageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomepageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomepageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomepageDataQuery(baseOptions?: Apollo.QueryHookOptions<GetHomepageDataQuery, GetHomepageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHomepageDataQuery, GetHomepageDataQueryVariables>(GetHomepageDataDocument, options);
      }
export function useGetHomepageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomepageDataQuery, GetHomepageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHomepageDataQuery, GetHomepageDataQueryVariables>(GetHomepageDataDocument, options);
        }
export function useGetHomepageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetHomepageDataQuery, GetHomepageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHomepageDataQuery, GetHomepageDataQueryVariables>(GetHomepageDataDocument, options);
        }
export type GetHomepageDataQueryHookResult = ReturnType<typeof useGetHomepageDataQuery>;
export type GetHomepageDataLazyQueryHookResult = ReturnType<typeof useGetHomepageDataLazyQuery>;
export type GetHomepageDataSuspenseQueryHookResult = ReturnType<typeof useGetHomepageDataSuspenseQuery>;
export type GetHomepageDataQueryResult = Apollo.QueryResult<GetHomepageDataQuery, GetHomepageDataQueryVariables>;