import React from "react";
import { MembershipDialog } from "../blocks/MembershipDialog";

export const MembershipDialogContext = React.createContext<{
  isDialogOpen: boolean;
  setIsDialogOpen: (open: boolean) => void;
}>({
  isDialogOpen: false,
  setIsDialogOpen: (open) => {
    return;
  },
});

export const MembershipDialogContextProvider: React.FC<any> = ({
  children,
}) => {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  return (
    <MembershipDialogContext.Provider value={{ isDialogOpen, setIsDialogOpen }}>
      {children}
      <MembershipDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
    </MembershipDialogContext.Provider>
  );
};
