import { useNavigate, useParams } from "react-router-dom";
import { Avatar, Container, Link, Stack, Typography } from "@mui/material";
import { mergeTranslations } from "../utils/merge";
import { fileUrl } from "../apiClient";
import NewsArticleCard from "../blocks/NewsArticleCard";
import React, { useEffect } from "react";
import { BackIcon } from "../icons";
import { WysiwygText } from "../blocks/WysiwygText";
import { useGetAboutUsPersonDataQuery } from "../queries/generated/getAboutUsPerson.graphql";
import { useTranslation } from "react-i18next";

export default function AboutUsPersonPage() {
  const { id } = useParams();
  const { i18n } = useTranslation();

  const navigate = useNavigate();
  const { data } = useGetAboutUsPersonDataQuery({
    variables: { id: id ?? "1" },
  });

  const { name, photo } = data?.committee_members_by_id ?? {};
  const { position, bio } = mergeTranslations(
    i18n.language,
    data?.committee_members_by_id?.translations ?? [],
  );
  const imageUrl = photo?.id ? fileUrl(photo?.id) : "/person.svg";

  useEffect(() => {
    if (id === undefined || data?.committee_members_by_id === null) {
      navigate("/o-nas");
    }
  }, [id]);

  return (
    <Container className={"main"}>
      <Stack
        direction={"row"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        mb={2}
        ml={{ xs: 0, lg: -7 }}
      >
        <Link href={"/o-nas"} sx={{ p: 2 }}>
          <BackIcon />
        </Link>
        <Typography
          component={"h3"}
          fontSize={"40px"}
          color={"primary"}
          fontWeight={500}
          lineHeight={"135%"}
        >
          {data?.committee_members_by_id?.name}
        </Typography>
      </Stack>
      <Stack
        spacing={7}
        direction={{ xs: "column", md: "row" }}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Avatar
          src={imageUrl}
          alt={name}
          sx={{
            width: "100%",
            height: "100%",
            maxWidth: 200,
            maxHeight: 200,
            alignSelf: "center",
          }}
        />
        <WysiwygText dangerouslySetInnerHTML={{ __html: bio }} />
      </Stack>
    </Container>
  );
}
