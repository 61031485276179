import { Box, Grid, Stack, Typography } from "@mui/material";
import { fileUrl } from "../apiClient";
import { getText } from "../utils/getText";
import { useNavigate } from "react-router-dom";

interface Props {
  id: string;
  title: string;
  imageUrl: string | undefined;
  text: string;
}
export default function NewsArticleCard(props: Props) {
  const { id, title, imageUrl, text } = props;
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={4}
      onClick={() => navigate(`/aktuality/${id}`)}
      sx={{ cursor: "pointer" }}
      mb={{ xs: 6, md: 9 }}
    >
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: 275,
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack direction={"column"} spacing={2}>
          <Typography
            component="h4"
            fontSize={"30px"}
            lineHeight={"41px"}
            color={"primary"}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
            }}
          >
            {getText(text)}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
