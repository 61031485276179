import React, { FC } from "react";
import { Button, ButtonProps } from "@mui/material";
import { CloseIcon } from "../icons/CloseIcon";

export const CloseDialogButton: FC<ButtonProps> = ({ sx, ...props }) => (
  <Button
    {...props}
    endIcon={<CloseIcon />}
    sx={{
      color: "#000",
      textTransform: "uppercase",
      paddingLeft: 2,
      paddingRight: 2,
      ...sx,
    }}
  />
);
