import { Typography, TypographyProps } from "@mui/material";
import React, { FC } from "react";

export const FormLabel: FC<TypographyProps & { error?: boolean }> = ({
  error,
  sx,
  ...props
}) => (
  <Typography
    sx={{
      color: error ? "primary.main" : "rgba(0, 0, 0, 0.50)",
      fontSize: "18px",
      fontWeight: 400,
      lineHeight: "53px",
      ...sx,
    }}
    {...props}
  />
);
