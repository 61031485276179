export const CheckboxTrue = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="32"
      height="32"
      fill="white"
      stroke="#D9D9D9"
    />
    <path
      d="M22.7234 11L14.7092 19.3193L10.2766 14.6975L9 16.0286L14.7092 22L24 12.3311L22.7234 11Z"
      fill="#DB565B"
    />
  </svg>
);
