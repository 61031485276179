export const CheckboxFalse = () => (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="32"
      height="32"
      fill="white"
      stroke="#D9D9D9"
    />
  </svg>
);
