import React, { FC, PropsWithChildren } from "react";
import { Box, SxProps } from "@mui/material";

interface Props extends PropsWithChildren {
  image: string;
  sx?: SxProps;
}

export const Hero: FC<Props> = ({ image, sx, children }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: { xs: "center", sm: "flex-end" },
        // height: DEFAULT_HEIGHT,
        minHeight: { xs: "500px", md: "960px" },
        maxHeight: { xs: "520vh", md: "960px" },
        position: "relative",
        paddingTop: { xs: 6, md: 11 },
        paddingX: 2.5,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
