import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import { FormLabel } from "./FormLabel";

interface Props {
  name: string;
  label: string;
  control: any;
  required?: boolean;
}
export const FormTextField: FC<TextFieldProps & Props> = ({
  name,
  label,
  control,
  required,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field, fieldState }) => (
        <Stack>
          <FormLabel error={fieldState.invalid}>{label}</FormLabel>
          <TextField
            {...rest}
            {...field}
            fullWidth
            variant={"outlined"}
            size={"small"}
          />
        </Stack>
      )}
    />
  );
};
