import * as Types from '../../types/gql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMainPageDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMainPageDataQuery = { __typename?: 'Query', partners: Array<{ __typename?: 'partners', id: string, alt?: string | null, link?: string | null, logo?: { __typename?: 'directus_files', id: string, height?: number | null, width?: number | null } | null }> };


export const GetMainPageDataDocument = gql`
    query getMainPageData {
  partners(sort: "sort") {
    id
    alt
    logo {
      id
      height
      width
    }
    link
  }
}
    `;

/**
 * __useGetMainPageDataQuery__
 *
 * To run a query within a React component, call `useGetMainPageDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMainPageDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMainPageDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMainPageDataQuery(baseOptions?: Apollo.QueryHookOptions<GetMainPageDataQuery, GetMainPageDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMainPageDataQuery, GetMainPageDataQueryVariables>(GetMainPageDataDocument, options);
      }
export function useGetMainPageDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMainPageDataQuery, GetMainPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMainPageDataQuery, GetMainPageDataQueryVariables>(GetMainPageDataDocument, options);
        }
export function useGetMainPageDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetMainPageDataQuery, GetMainPageDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetMainPageDataQuery, GetMainPageDataQueryVariables>(GetMainPageDataDocument, options);
        }
export type GetMainPageDataQueryHookResult = ReturnType<typeof useGetMainPageDataQuery>;
export type GetMainPageDataLazyQueryHookResult = ReturnType<typeof useGetMainPageDataLazyQuery>;
export type GetMainPageDataSuspenseQueryHookResult = ReturnType<typeof useGetMainPageDataSuspenseQuery>;
export type GetMainPageDataQueryResult = Apollo.QueryResult<GetMainPageDataQuery, GetMainPageDataQueryVariables>;