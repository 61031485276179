export default [
  {
    name: "o-nas",
    route: "/o-nas",
    label: "navigace.oNas",
  },
  {
    name: "clenstvi",
    route: "/clenstvi",
    label: "navigace.clenstvi",
  },
  {
    name: "aktuality",
    route: "/aktuality",
    label: "navigace.aktuality",
  },
  {
    name: "soutez",
    route: "/soutez",
    label: "navigace.soutez",
    },
  {
    name: "kontakty",
    route: "/kontakty",
    label: "navigace.kontakty",
  }
]