import React from "react";
import { SvgIcon } from "@mui/material";

interface Props {
  sx?: any;
}

export const MenuIcon = ({ sx }: Props) => (
  <SvgIcon sx={sx}>
    <svg
      width="28"
      height="18"
      viewBox="0 0 28 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="0.925781"
        y1="9.30741"
        x2="28.0007"
        y2="9.30741"
        stroke="#494949"
        strokeWidth="2.46136"
      />
      <line
        x1="0.925781"
        y1="1.92362"
        x2="28.0007"
        y2="1.92362"
        stroke="#494949"
        strokeWidth="2.46136"
      />
      <line
        x1="0.925781"
        y1="16.6922"
        x2="28.0007"
        y2="16.6922"
        stroke="#494949"
        strokeWidth="2.46136"
      />
    </svg>
  </SvgIcon>
);
