import { SvgIcon, SxProps } from "@mui/material";
import { FC } from "react";

interface Props {
  sx?: SxProps;
}
export const BackIcon: FC<Props> = ({ sx }) => (
  <SvgIcon sx={sx}>
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 33L0 18L15 3L17.6625 5.6625L5.325 18L17.6625 30.3375L15 33Z"
        fill="black"
      />
    </svg>
  </SvgIcon>
);
