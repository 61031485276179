import React from "react";
import { useTranslation } from "react-i18next";
import { useGetContactPageDataQuery } from "../queries/generated/getContactPage.graphql";
import { mergeTranslations } from "../utils/merge";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { WysiwygText } from "../blocks/WysiwygText";
import { useGetContestPageDataQuery } from "../queries/generated/getContestPage.graphql";
import { useGetHomepageDataQuery } from "../queries/generated/getHomepage.graphql";
import NewsArticleCard from "../blocks/NewsArticleCard";
import { fileUrl } from "../apiClient";
import { MembershipIcon } from "../icons";
import { useNavigate, useNavigation } from "react-router-dom";
import { Hero } from "../components/Hero";

export default function Homepage() {
  const { t, i18n } = useTranslation();
  const { data } = useGetHomepageDataQuery();

  const navigate = useNavigate();
  const { text_title, text } = mergeTranslations(
    i18n.language,
    data?.homepage?.translations || [],
  );

  return (
    <>
      <Hero image={fileUrl(data?.homepage?.hero_image?.id || "") || ""}>
        <Container sx={{ marginTop: "120px", minHeight: 520 }}>
          <Grid container>
            <Grid item xs={12} md={6} mb={4}>
              <Typography variant={"h2"} color={"#fff"}>
                {t("uvod.nadpis")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant={"subtitle1"} color={"#fff"}>
                {t("uvod.text")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Hero>
      <Container className={"main"} sx={{ paddingTop: "60px !important" }}>
        <Typography variant={"h3"} color={"primary"}>
          {text_title}
        </Typography>
        <WysiwygText dangerouslySetInnerHTML={{ __html: text }} />

        <Typography variant={"h3"} color={"primary"} mt={10}>
          {t("aktuality.nadpis")}
        </Typography>

        {data?.news.map((article) => {
          const { title, text } = mergeTranslations(
            i18n.language,
            article.translations || [],
          );

          return (
            <NewsArticleCard
              key={article.id}
              id={article.id}
              title={title}
              text={text}
              imageUrl={
                article.preview_image?.id && fileUrl(article.preview_image?.id)
              }
            />
          );
        })}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 14,
          }}
        >
          <Button
            variant={"contained"}
            color={"primary"}
            sx={{ height: 50, flex: "0 0 auto" }}
            onClick={() => navigate("/aktuality")}
          >
            {t("aktuality.zobrazitDalsi")}
          </Button>
        </Box>
      </Container>
    </>
  );
}
