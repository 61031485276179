import React, { FC } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

export interface Props {
  size?: "small" | "large";
}
export const Logo: FC<Props> = ({ size }) => (
  <Box
    component={"a"}
    href={"/"}
    width={size === "small" ? "240px" : "370px"}
    height={size === "small" ? "58px" : "88px"}
    sx={{
      backgroundImage: `url(/logo.svg)`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      minHeight: "58px",
      maxHeight: "88px",
      minWidth: "240px",
      maxWidth: "370px",
    }}
  />
);
