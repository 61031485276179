import React from "react";
import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";
import { CheckboxFalse } from "../icons/CheckboxFalse";
import { CheckboxTrue } from "../icons/CheckboxTrue";

interface Props {
  name: string;
  label: string;
  control: any;
}

export const FormCheckboxField = ({ name, label, control, ...rest }: Props) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field: props }) => (
            <Checkbox
              {...props}
              checked={props.value}
              onChange={(e) => props.onChange(e.target.checked)}
              icon={<CheckboxFalse />}
              checkedIcon={<CheckboxTrue />}
            />
          )}
        />
      }
      label={label}
      sx={{
        "& .MuiFormControlLabel-label": {
          fontSize: "18px",
          fontWeight: 500,
          lineHeight: "35px",
          color: "#494949",
          whiteSpace: "nowrap",
        },
      }}
    />
  );
};
