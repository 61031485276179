import React from "react";
import { Box, styled } from "@mui/material";
import Theme, { pxToRem } from "../theme";

export const WysiwygText = styled(Box)(({ theme }) => {
  return {
    color: "#555",
    fontSize: "21px !important",
    fontWeight: 300,
    lineHeight: "150%",
    [Theme.breakpoints.down("sm")]: {
      fontSize: pxToRem(18),
    },

    "& h3": {
      color: theme.palette.primary.main,
      fontFamily: "Maitree",
      fontSize: pxToRem(45),
      fontWeight: 500,
      lineHeight: 1.23,
      margin: 0,
      [Theme.breakpoints.down("sm")]: {
        fontSize: pxToRem(35),
      },
    },
    "& h4": {
      color: theme.palette.primary.main,
      fontFamily: "Maitree",
      fontSize: pxToRem(35),
      fontWeight: 500,
      lineHeight: 1.23,
    },
    "& h5": {
      fontSize: pxToRem(21),
      fontWeight: 500,
      lineHeight: pxToRem(30),
      marginBottom: 0,
    },
    "& p": {
      "& img:only-child": {
        width: "100%",
      },
      "& img": {
        width: "100%",
        borderRadius: "0",
      },
      fontSize: pxToRem(21),
      fontWeight: 300,
      lineHeight: "142%",
      marginTop: 0,
      marginBottom: theme.spacing(2.5),
    },
    "& hr": {
      color: theme.palette.divider,
      size: 1,
    },
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "underline",
      ":link": { color: theme.palette.primary.main },
      ":active": { color: theme.palette.primary.main },
      ":visited": { color: theme.palette.primary.main },
      ":hover": { color: theme.palette.primary.dark },
    },
    "& ul": {
      paddingLeft: 24,

      "& li": {
        "& span": {
          verticalAlign: "middle",
        },
      },
    },
    "& blockquote": {
      color: theme.palette.grey[800],
      borderRadius: "10px",
      backgroundColor: "#F5F7F9",
      fontSize: "24px",
      fontWeight: 600,
      lineHeight: "150%",
      position: "relative",
      margin: `${theme.spacing(4.5)} 0`,
      [theme.breakpoints.down("sm")]: {
        padding: `${theme.spacing(4.25)} ${theme.spacing(6)} !important`,
      },
      [theme.breakpoints.up("md")]: {
        padding: `${theme.spacing(4.5)} ${theme.spacing(13)} !important`,
      },
      ":after": {
        content:
          'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAyOCAyOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggaWQ9IlF1b3RlcyIgb3BhY2l0eT0iMC4xNSIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMC4zOTk4IDBWNi40MDA1MUM4Ljk0MDIyIDYuNDAwNTEgNi4zNjkwNiA2LjUyMTggNi4zNjg5MSAxMi4zNjUyVjE1LjQwMTJIMTIuNTk5N1YyOC4wMDIySDBWMTUuNDAxMlYxMi4zNjUyQzAgNy43NzExIDEuMTg4NzggNC4zNTk1NCAzLjc4NjAzIDIuMjI1MDlDNS41ODI3IDAuNzQ4NjExIDcuNzExMjIgMCAxMC4zOTk4IDBaTTI1LjggMFY2LjQwMDUxQzI0LjM0MDUgNi40MDA1MSAyMS43NjkzIDYuNTIxOCAyMS43NjkyIDEyLjM2NTJWMTUuNDAxMkgyOFYyOC4wMDIySDE1LjQwMDNWMTUuNDAxMlYxMi4zNjUyQzE1LjQwMDMgNy43NzExIDE2LjU4OSA0LjM1OTU0IDE5LjE4NjMgMi4yMjUwOUMyMC45ODMgMC43NDg2MTEgMjMuMTExNSAwIDI1LjggMFoiIGZpbGw9IiNBOEI3Q0MiLz4KPC9zdmc+Cg==")',
        position: "absolute",

        fontSize: "6em",
        fontStyle: "italic",
        color: "#A8B7CC",
        zIndex: 1,
        [theme.breakpoints.down("sm")]: {
          top: "-24px",
          left: "28px",
        },
        [theme.breakpoints.up("md")]: {
          top: "-24px",
          left: "85px",
        },
      },

      "& p": {
        margin: 0,
      },
    },
  };
});
