import { Box } from "@mui/material";
import { fileUrl } from "../apiClient";
import React, { FC } from "react";

interface Props {
  alt?: string | null;
  logo: string;
  link?: string | null;
}

export const PartnerLogo: FC<Props> = ({ alt, logo, link }) => {
  return (
    <Box
      component={"a"}
      href={link ?? "#"}
      target={"_blank"}
      sx={{
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        component={"img"}
        sx={{ objectFit: "contain" }}
        alt={alt ?? undefined}
        src={logo}
      />
    </Box>
  );
};
