import { mergeWith } from "lodash";

export function mergeTranslations(language: string, translations: any[]) {
  translations = translations?.filter((t) => t?.languages_code?.code) ?? [];

  const lng_codes: string[] = [];
  if (language.startsWith("cs")) {
    lng_codes.push("cs");
  } else {
    lng_codes.push(language);
    lng_codes.push("cs");
  }

  const trans1 =
    translations.find((t) => t.languages_code?.code === lng_codes[0]) ?? {};

  const trans2 =
    translations.find((t) => t.languages_code?.code === lng_codes[1]) ?? {};

  return mergeWith(trans1, trans2, (a, b) => (b === null ? a : undefined));
}
