// import "@fontsource/maitree/latin-ext-500.css";
// import "@fontsource/lexend/300.css";
// import "@fontsource/lexend/700.css";

import React from "react";
import Navigation from "./blocks/Navigation";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./blocks/Footer";
import { Box } from "@mui/material";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { client } from "./apiClient";
import AboutUsPersonPage from "./pages/AboutUsPersonPage";
import Homepage from "./pages/Homepage";
import { MembershipDialog } from "./blocks/MembershipDialog";
import { MembershipDialogContextProvider } from "./context/MembershipDialogContext";

const AboutUsPage = React.lazy(() => import("./pages/AboutUsPage"));
const ContactPage = React.lazy(() => import("./pages/ContactPage"));
const ContestPage = React.lazy(() => import("./pages/ContestPage"));
const MembershipPage = React.lazy(() => import("./pages/MembershipPage"));
const NewsPage = React.lazy(() => import("./pages/NewsPage"));
const NewsArticlePage = React.lazy(() => import("./pages/NewsArticlePage"));

function App() {
  return (
    <ApolloProvider client={client}>
      <MembershipDialogContextProvider>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Router>
            <Routes>
              <Route
                path={"*"}
                element={
                  <React.Suspense fallback={<>...</>}>
                    <Navigation />
                  </React.Suspense>
                }
              />
            </Routes>

            <Routes>
              <Route
                path={"/"}
                element={
                  <React.Suspense fallback={<>...</>}>
                    <Homepage />
                  </React.Suspense>
                }
              />
              <Route
                path={"/o-nas"}
                element={
                  <React.Suspense fallback={<>...</>}>
                    <AboutUsPage />
                  </React.Suspense>
                }
              />
              <Route
                path={"/o-nas/:id"}
                element={
                  <React.Suspense fallback={<>...</>}>
                    <AboutUsPersonPage />
                  </React.Suspense>
                }
              />
              <Route
                path={"/aktuality"}
                element={
                  <React.Suspense fallback={<>...</>}>
                    <NewsPage />
                  </React.Suspense>
                }
              />
              <Route
                path={"/aktuality/:id"}
                element={
                  <React.Suspense fallback={<>...</>}>
                    <NewsArticlePage />
                  </React.Suspense>
                }
              />
              <Route
                path={"/clenstvi"}
                element={
                  <React.Suspense fallback={<>...</>}>
                    <MembershipPage />
                  </React.Suspense>
                }
              />
              <Route
                path={"/soutez"}
                element={
                  <React.Suspense fallback={<>...</>}>
                    <ContestPage />
                  </React.Suspense>
                }
              />
              <Route
                path={"/kontakty"}
                element={
                  <React.Suspense fallback={<>...</>}>
                    <ContactPage />
                  </React.Suspense>
                }
              />
            </Routes>
            <Routes>
              <Route path={"*"} element={<Footer />} />
            </Routes>
          </Router>
        </Box>
      </MembershipDialogContextProvider>
    </ApolloProvider>
  );
}

export default App;
